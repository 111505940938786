import "./LoginPage.css";
import LoginBox from "./LoginBox";
import { objectToQueryString, getLogo, query, HOME, win } from "./utils";
import { useDocumentTitle } from "./Title";

win.sessionStorage.setItem("redirectUrl", query.redirectUrl || "");

export default function LoginPage() {
  useDocumentTitle("登录");

  const loginRes = (res) => {
    win.location.href =
      (query.redirectUrl || HOME) +
      "?" +
      objectToQueryString({ token: res.token });
  };

  if (query.token) {
    const redirectUrl = win.sessionStorage.getItem("redirectUrl");
    console.log(
      (redirectUrl || HOME) + "?" + objectToQueryString({ token: query.token })
    );
    win.location.href =
      (redirectUrl || HOME) + "?" + objectToQueryString({ token: query.token });
    return null;
  }

  return (
    <div className="login">
      <a className="login_logo" href={HOME}>
        <img alt="logo" src={require("./" + getLogo() + ".png")}></img>
      </a>
      <LoginBox onSuccess={loginRes} />
      <div className="login_footer">
        <a href="https://jianguoke.cn">JianGuoKe.cn - 坚果壳</a>
      </div>
    </div>
  );
}
