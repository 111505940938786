import { Route, Routes } from "react-router-dom";
import Markdown from "react-markdown";
import "./Policies.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDocumentTitle } from "./Title";

const api = axios.create();

function Md(props) {
  const [txt, setTxt] = useState("");

  useDocumentTitle(props.name === "privacy_policy" ? "隐私政策" : "服务协议");

  useEffect(() => {
    api
      .get("/" + props.name + ".md")
      .then((res) => setTxt(res.data))
      .catch(console.log);
  }, [props]);
  return <Markdown>{txt}</Markdown>;
}

export default function Policies(props) {
  return (
    <div className="login_portal">
      <header>
        <span
          className="logo"
          onClick={() => {
            global.history.back();
          }}
        >
          <img className="back" src={require("./back.png")} alt="后退"></img>
          坚果壳
        </span>
      </header>
      <p className="body">
        <Routes>
          <Route
            path="privacy_policy"
            element={<Md name="privacy_policy"></Md>}
          />
          <Route
            path="service_agreement"
            element={<Md name="service_agreement"></Md>}
          />
        </Routes>
      </p>
      <p className="footer">@2024-2025 坚果壳</p>
    </div>
  );
}
