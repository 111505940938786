import { useEffect } from "react";

export const useDocumentTitle = (title) => {
  useEffect(() => {
    // const prevTitle = document.title;
    document.title = title + ' | 坚果壳';

    // // 组件卸载时恢复之前的标题
    // return () => {
    //   document.title = prevTitle;
    // };
  }, [title]);
};
